import { createApi } from '@reduxjs/toolkit/query/react';

import { Settlement, Street } from '@/features/locations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/locations`,
  }),
  endpoints: builder => {
    return {
      getSettlements: builder.query<Settlement[], { text: string }>({
        query: input => ({
          url: `/settlements?text=${input.text}`,
        }),
      }),
      getStreets: builder.query<
        Street[],
        { settlementId: string; text: string }
      >({
        query: input => ({
          url: `/streets?settlementId=${input.settlementId}&text=${input.text}`,
        }),
      }),
    };
  },
});

export const { useGetSettlementsQuery, useGetStreetsQuery } = locationsApi;
