import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

const MeteringPointInformation = React.lazy(
  () => import('@/pages/MeteringPointDetails/views/Information'),
);

// TODO: move to Pages in case if this needed
export const MeteringPointsChildrenPath = {
  METERING_POINT_ACTS: 'metering-point-acts',
  METERING_NODE: 'metering-node',
};

export const meteringPointsRoutes: RouteObject[] = [
  {
    element: <MeteringPointInformation />,
    index: true,
  },
  {
    // TODO: acts?
    path: MeteringPointsChildrenPath.METERING_POINT_ACTS,
    element: <div>Акти по точці обліку</div>,
  },
  {
    // Вузел обліку газу  ВОГ / GasMeteringNode
    path: MeteringPointsChildrenPath.METERING_NODE,
    element: <div>ВОГ</div>,
  },
];
