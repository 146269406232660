import { NavLinkProps } from 'react-router-dom';

import { styled } from '@mui/joy';
import Box from '@mui/joy/Box';
import Stack, { StackProps } from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

export const Container = styled(Stack, {
  shouldForwardProp: prop => prop !== 'minimized',
})<
  StackProps &
    NavLinkProps & {
      minimized?: boolean;
    }
>(({ theme, minimized }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: minimized ? 'center' : 'flex-start',
  padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
  borderRadius: theme.spacing(0.5),
  textDecoration: 'none',
  '&.active': {
    background: theme.palette.neutral[200],
    '&:hover': {
      background: theme.palette.neutral[400],
    },
    '.MuiSvgIcon-root': {
      fill: theme.palette.neutral[700],
    },
  },
  '&:hover': {
    background: 'rgba(255,255,255, 0.1)',
  },
  '.MuiSvgIcon-root': {
    width: 18,
    height: 18,
    fill: theme.palette.common.white,
  },
}));

export const IconPlaceholder = styled(Box)({
  width: '18px',
  height: '18px',
});

export const Caption = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.sm,
  padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
  '.active &': {
    color: theme.palette.neutral[700],
  },
}));
