import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringPointType } from './types';

export const meteringPointTypeApi = createApi({
  reducerPath: 'meteringPointTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getMeteringPointsTypes: builder.query<MeteringPointType[], any>({
      query: () => ({
        url: '/metering-point-types',
      }),
    }),
  }),
});

export const { useGetMeteringPointsTypesQuery } = meteringPointTypeApi;
