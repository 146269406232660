import { BoxProps, styled } from '@mui/joy';
import Box from '@mui/joy/Box';

export const Container = styled(Box)<BoxProps & { minimized?: boolean }>(
  ({ theme, minimized }) => ({
    margin: `auto ${theme.spacing(1.5)} ${theme.spacing(3)}`,
    padding: minimized ? `${theme.spacing(2)} 0` : theme.spacing(1),
    background: theme.palette.neutral['700'],
    borderRadius: theme.spacing(2),
    '.nav-link ': {
      '&.active': {
        '& > .MuiStack-root': {
          background: theme.palette.neutral['200'],
        },
        '& .MuiTypography-root': {
          color: theme.palette.neutral['700'],
        },
        '& .MuiSvgIcon-root': {
          fill: theme.palette.neutral['700'],
        },
        '& svg path': {
          fill: theme.palette.neutral['700'],
        },
      },
      '& > .MuiStack-root': {
        padding: minimized
          ? `${theme.spacing(1)} ${theme.spacing(2)}`
          : `${theme.spacing(0.25)} ${theme.spacing(1)}`,
        borderRadius: theme.spacing(0.5),
      },
    },
    '.category-label': { paddingRight: theme.spacing(1) },
  }),
);
