import React, { ReactNode } from 'react';

import { createBrowserRouter } from 'react-router-dom';
import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';
import { gasDistributionStationRoutes } from '@/pages/GasDistributionStationDetails/routes';
import { gasDistributionStationGasThreadRoutes } from '@/pages/GasDistributionStationGasThread/routes';
import { gasThreadRoutes } from '@/pages/GasThreadDetails/routes';
import { gasThreadMeteringPointsRoutes } from '@/pages/GasThreadMeteringPoints/routes';
import { meteringPointsRoutes } from '@/pages/MeteringPointDetails/routes';

import PageLayout from './components/PageLayout';

const AuthCallback = React.lazy(() => import('@/pages/auth/AuthCallback'));
const ChangePassword = React.lazy(() => import('@/pages/auth/ChangePassword'));
const Onboarding = React.lazy(() => import('@/pages/Onboarding'));
const Organizations = React.lazy(() => import('@/pages/Organizations'));
const OrganizationDetails = React.lazy(
  () => import('@/pages/OrganizationDetails'),
);
const OrganizationInformation = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Information'),
);
const OrganizationEmployees = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Employees'),
);
const OrganizationEmployeeDetails = React.lazy(
  () => import('@/forms/EmployeeForm'),
);
const OrganizationSubdivisions = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Subdivisions'),
);
const OrganizationSubdivisionDetails = React.lazy(
  () => import('@/pages/OrganizationDetails/views/SubdivisionDetails'),
);
const OrganizationSubdivisionDetailsInformation = React.lazy(
  () =>
    import(
      '@/pages/OrganizationDetails/views/SubdivisionDetails/views/Information'
    ),
);
const UserGroups = React.lazy(() => import('@/pages/UserGroups'));
const UserGroupDetails = React.lazy(() => import('@/pages/UserGroupDetails'));
const Users = React.lazy(() => import('@/pages/Users'));
const UserDetails = React.lazy(() => import('@/forms/UserForm'));

const GasDistributionStations = React.lazy(
  () => import('@/pages/GasDistributionStations'),
);
const GasDistributionStationDetails = React.lazy(
  () => import('@/pages/GasDistributionStationDetails'),
);
const GasDistributionStationGasThread = React.lazy(
  () => import('@/pages/GasDistributionStationGasThread'),
);

const GasThreads = React.lazy(() => import('@/pages/GasThreads'));
const GasThreadDetails = React.lazy(() => import('@/pages/GasThreadDetails'));
const GasThreadMeteringPoints = React.lazy(
  () => import('@/pages/GasThreadMeteringPoints'),
);

const Counterparts = React.lazy(() => import('@/pages/Counterparts'));

const MeteringPoints = React.lazy(() => import('@/pages/MeteringPoints'));
const MeteringPointDetails = React.lazy(
  () => import('@/pages/MeteringPointDetails'),
);

const SectorBridges = React.lazy(() => import('@/pages/SectorBridges'));
const SectorBridgeDetails = React.lazy(
  () => import('@/pages/SectorBridgeDetails'),
);
const SectorBridgeDetailsBridgeActs = React.lazy(
  () => import('@/pages/SectorBridgeDetails/views/BridgeActs'),
);
const SectorBridgeDetailsSectors = React.lazy(
  () => import('@/pages/SectorBridgeDetails/views/Sectors'),
);
const BridgeActs = React.lazy(() => import('@/pages/BridgeActs'));

const Roles = React.lazy(() => import('@/pages/Roles'));
const RoleDetails = React.lazy(() => import('@/pages/RoleDetails'));
const CommercialAccountingPoints = React.lazy(
  () => import('@/pages/CommercialAccountingPoints'),
);

const Contracts = React.lazy(() => import('@/pages/Contracts'));

export interface Breadcrumbs {
  title: string;
  path: string;
}

export interface RouteLayer {
  path: string;
  element: ReactNode;
  title?: ReactNode;
  crumbs?: Breadcrumbs[];
  children?: RouteObject[];
  index?: boolean;
}

const routes: RouteLayer[] = [
  {
    path: Pages.ROOT,
    element: <Organizations />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId`,
    element: <OrganizationDetails />,
    children: [
      {
        path: '',
        element: <OrganizationInformation />,
      },
      {
        path: Pages.EMPLOYEES_PART,
        element: <OrganizationEmployees />,
        children: [
          {
            path: `:employeeId`,
            element: <OrganizationEmployeeDetails />,
          },
        ],
      },
      {
        path: Pages.SUBDIVISIONS_PART,
        element: <OrganizationSubdivisions />,
      },
    ],
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.SUBDIVISIONS_PART}/:subId`,
    element: <OrganizationSubdivisionDetails />,
    children: [
      { path: '', element: <OrganizationSubdivisionDetailsInformation /> },
      {
        path: Pages.EMPLOYEES_PART,
        element: <OrganizationEmployees />,
        children: [
          {
            path: `:employeeId`,
            element: <OrganizationEmployeeDetails />,
          },
        ],
      },
    ],
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.ROLES_PART}`,
    element: <Roles />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.SUBDIVISIONS_PART}/:subId/${Pages.ROLES_PART}`,
    element: <Roles />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.ROLES_PART}/:roleId`,
    element: <RoleDetails />,
  },
  {
    path: `/${Pages.ORGANIZATIONS_PART}/:orgId/${Pages.SUBDIVISIONS_PART}/:subId/${Pages.ROLES_PART}/:roleId`,
    element: <RoleDetails />,
  },
  { path: Pages.USER_GROUPS, element: <UserGroups /> },
  { path: `${Pages.USER_GROUPS}/:groupId`, element: <UserGroupDetails /> },
  {
    path: Pages.USERS,
    element: <Users />,
    children: [
      {
        path: ':userId',
        element: <UserDetails />,
      },
    ],
  },
  {
    path: Pages.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },
  {
    path: Pages.GAS_DISTRIBUTION_STATIONS,
    element: <GasDistributionStations />,
  },
  {
    path: `${Pages.GAS_DISTRIBUTION_STATIONS}/:stationId`,
    element: <GasDistributionStationDetails />,
    children: gasDistributionStationRoutes,
  },
  {
    path: `${Pages.GAS_DISTRIBUTION_STATIONS}/:stationId/gas-threads/:gasThreadId`,
    element: <GasDistributionStationGasThread />,
    children: gasDistributionStationGasThreadRoutes,
  },
  {
    path: Pages.GAS_THREADS,
    element: <GasThreads />,
  },
  {
    path: Pages.CONTRACTS,
    element: <Contracts />,
  },
  {
    path: `${Pages.GAS_THREADS}/:gasThreadId`,
    element: <GasThreadDetails />,
    children: gasThreadRoutes,
  },
  {
    path: `${Pages.GAS_THREADS}/:gasThreadId/${Pages.METERING_POINTS}`,
    element: <GasThreadMeteringPoints />,
    children: gasThreadMeteringPointsRoutes,
  },
  {
    path: Pages.METERING_POINTS,
    element: <MeteringPoints />,
  },
  {
    path: `${Pages.METERING_POINTS}/:meteringPointId`,
    element: <MeteringPointDetails />,
    children: meteringPointsRoutes,
  },
  {
    path: Pages.SECTOR_BRIDGES,
    element: <SectorBridges />,
  },
  {
    path: `${Pages.SECTOR_BRIDGES}/:sectorBridgeId`,
    element: <SectorBridgeDetails />,
    children: [
      { path: '', element: <SectorBridgeDetailsBridgeActs /> },
      { path: Pages.SECTORS, element: <SectorBridgeDetailsSectors /> },
    ],
  },
  {
    path: Pages.BRIDGE_ACTS,
    element: <BridgeActs />,
  },
  {
    path: Pages.COUNTERPARTS,
    element: <Counterparts />,
  },
  {
    path: Pages.COMMERCIAL_ACCOUNTING_POINTS,
    element: <CommercialAccountingPoints />,
  },
];

export const getRoutes = () =>
  createBrowserRouter([
    // Page for oidc authorization flow
    {
      path: '/callback',
      element: <AuthCallback />,
    },
    {
      path: Pages.ONBOARDING,
      element: <Onboarding />,
    },
    {
      path: '/',
      element: <PageLayout />,
      children: routes.map(({ path, element, children }) => ({
        path,
        element,
        children,
      })),
    },
  ]);
