import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import data from './mock';
import { Counterpart } from './types';

interface CounterpartState {
  data: Counterpart[];
}

const initialState: CounterpartState = {
  data,
};

const counterpartSlice = createSlice({
  name: 'counterpart',
  initialState,
  reducers: {
    getCounterpartSuccess: (
      state,
      { payload }: PayloadAction<Counterpart[]>,
    ) => {
      state.data = payload;
    },
  },

  selectors: {
    counterpartSelector: state => state.data,
    counterpartByIdSelector: (state, id: string) =>
      id ? state.data.find(org => org.id === id) : undefined,
  },
});

export const { getCounterpartSuccess } = counterpartSlice.actions;

export const { counterpartSelector, counterpartByIdSelector } =
  counterpartSlice.selectors;

export default counterpartSlice;
