import { MeteringPoint } from '@/features/meteringPoint/types';

export const transformFn = (point: unknown): MeteringPoint => {
  const typedPoint = point as MeteringPoint;

  return {
    ...typedPoint,
    // @ts-expect-error
    isIncoming: typedPoint.isIncoming === 'true',
    // @ts-expect-error
    isActive: typedPoint.isActive === 'true',
    // @ts-expect-error
    ourBalance: typedPoint.ourBalance === 'true',
    // @ts-expect-error
    withoutMeteringDevice: typedPoint.withoutMeteringDevice === 'true',
    // @ts-expect-error
    isTechnical: typedPoint.isTechnical === 'true',
  };
};
