import { createApi } from '@reduxjs/toolkit/query/react';

import {
  SectorBridge,
  SectorBridgeFilters,
} from '@/features/sectorBridges/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';

export const sectorBridgesApi = createApi({
  reducerPath: 'sectorBridgesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/sectors/sector-bridges`,
  }),
  tagTypes: ['SectorBridges', 'SectorBridge'],
  endpoints: builder => ({
    getSectorBridges: builder.query<SectorBridge[], SectorBridgeFilters | void>(
      {
        // TODO add filters logic
        query: () => ({
          url: `/`,
        }),
        providesTags: ['SectorBridges'],
      },
    ),
    getSectorBridgeById: builder.query<SectorBridge, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'SectorBridge', id }],
    }),
    createSectorBridge: builder.mutation<SectorBridge, SectorBridge>({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'SectorBridges',
        { type: 'SectorBridge', id: result.id },
      ],
    }),
    updateSectorBridgeById: builder.mutation<SectorBridge, SectorBridge>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'SectorBridges',
        { type: 'SectorBridge', id: result.id },
      ],
    }),
    deleteSectorBridgeById: builder.mutation<SectorBridge, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'SectorBridges',
        { type: 'SectorBridge', id },
      ],
    }),
  }),
});

export const {
  useGetSectorBridgesQuery,
  useGetSectorBridgeByIdQuery,
  useCreateSectorBridgeMutation,
  useUpdateSectorBridgeByIdMutation,
  useDeleteSectorBridgeByIdMutation,
} = sectorBridgesApi;
