export default [
  {
    id: 'ТИС000009',
    name: 'Южкокс',
    category: 'I категорія',
    pressureCategory: 'Середній',
    measurementPeriod: 'Код=1',
    typeAccountingPoint: '1',
    typeIndustryObject: '1',
    counterparts: 'Левко Олександр Миколайович',
    sectop: 'hjk',
    location: 'вул.Шевченка, 9',
    subdivision: { name: 'Київ Газ' },
    isActive: 'on',
    changeStatus: '2024-01-09',
  },
  {
    id: 'ТИС000007',
    name: ' Южкокс Южкокс Южкокс',
    category: 'I категорія',
    pressureCategory: 'Середній',
    measurementPeriod: 'Код=1',
    typeAccountingPoint: '1',
    typeIndustryObject: '1',
    counterparts: 'Дмитро Олександр Миколайович',
    sectop: 'hjk',
    location: 'вул.Шевченка, 10',
    subdivision: { name: 'ЖитомирГазмережі' },
    isActive: 'off',
    changeStatus: '2024-12-09',
  },
  {
    id: 'ТИС0000111',
    name: ' Story',
    category: 'I категорія',
    pressureCategory: 'Середній',
    measurementPeriod: 'Код=1',
    typeAccountingPoint: '1',
    typeIndustryObject: '1',
    counterparts: 'Дмитро Олександр Миколайович',
    sectop: 'hjk',
    location: 'вул.Шевченка, 10',
    subdivision: { name: 'ЖитомирГазмережі' },
    isActive: 'notDefined',
    changeStatus: '2024-11-09',
  },
  {
    id: 'ТИС000004',
    name: ' Южкокс Южкокс Южкокс 444',
    category: 'I категорія',
    pressureCategory: 'Середній',
    measurementPeriod: 'Код=1',
    typeAccountingPoint: '1',
    typeIndustryObject: '1',
    counterparts: 'Савченко Олександр Миколайович',
    sectop: 'hjk',
    location: 'вул.Шевченка, 1',
    subdivision: { name: 'ЖитомирГазмережі' },
    isActive: 'end',
    changeStatus: '2024-10-09',
  },
] as any[];
