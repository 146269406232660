import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';

const GasThreadInfo = React.lazy(
  () => import('@/pages/GasThreadDetails/views/Information'),
);
const GasThreadMeteringPoints = React.lazy(
  () => import('@/pages/GasThreadDetails/views/MeteringPoints'),
);

export const gasThreadRoutes: RouteObject[] = [
  {
    element: <GasThreadInfo />,
    index: true,
  },
  {
    path: Pages.METERING_POINTS,
    element: <GasThreadMeteringPoints />,
  },
  {
    path: Pages.SECTORS,
    element: <div>Сектори</div>,
  },
];
