import { createApi } from '@reduxjs/toolkit/query/react';

import { transformFn } from '@/features/meteringPoint/transformResponse';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';
import { MeteringPoint } from './types';

export const meteringPointApi = createApi({
  reducerPath: 'meteringPointApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getMeteringPoints: builder.query<MeteringPoint[], any>({
      query: () => ({
        url: '/metering-points',
      }),
      transformResponse(baseQueryReturnValue: unknown): MeteringPoint[] {
        return Array.isArray(baseQueryReturnValue)
          ? baseQueryReturnValue.map(transformFn)
          : [];
      },
    }),
    getMeteringPointById: builder.query<MeteringPoint, string>({
      query: id => ({
        url: `/metering-points/${id}`,
      }),
      transformResponse(baseQueryReturnValue: unknown): MeteringPoint {
        return baseQueryReturnValue === undefined
          ? ({} as MeteringPoint)
          : transformFn(baseQueryReturnValue);
      },
    }),
    createMeteringPoint: builder.mutation<MeteringPoint, any>({
      query: data => ({
        url: '/metering-points',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetMeteringPointsQuery,
  useLazyGetMeteringPointByIdQuery,
  useGetMeteringPointByIdQuery,
  useCreateMeteringPointMutation,
} = meteringPointApi;
