export default [
  {
    id: 'ТИС000002',
    counterparts: {
      firstName: 'Фахівець',
      lastName: 'відділу ',
      thirdName: 'іванов',
    },
    category: 'Релігія',
    status: true,
    startDate: '30.01.98',
    endDate: '30.01.99',
  },

  {
    id: 'ТИС000004',
    counterparts: {
      firstName: 'Фахівець',
      lastName: 'відділу ',
      thirdName: 'іванов',
    },
    category: 'Релігія',
    status: false,
    startDate: '30.01.98',
    endDate: '30.01.99',
  },
  {
    id: 'ТИС000005',
    counterparts: {
      firstName: 'Фахівець',
      lastName: 'відділу ',
      thirdName: 'іванов',
    },
    category: 'Релігія',
    status: true,
    startDate: '30.01.98',
    endDate: '30.01.99',
  },
  {
    id: 'ТИС000006',
    counterparts: {
      firstName: 'Фахівець',
      lastName: 'відділу ',
      thirdName: 'іванов',
    },
    category: 'Релігія',
    status: false,
    startDate: '30.01.98',
    endDate: '30.01.99',
  },
  {
    id: 'ТИС000007',
    counterparts: {
      firstName: 'Фахівець',
      lastName: 'відділу ',
      thirdName: 'іванов',
    },
    category: 'Релігія',
    status: true,
    startDate: '30.01.98',
    endDate: '30.01.99',
  },
  {
    id: 'ТИС000008',
    counterparts: {
      firstName: 'іванов',
      lastName: 'іванов',
      thirdName: 'іванов',
    },
    category: 'Релігія',
    status: true,
    startDate: '30.01.98',
    endDate: '30.01.99',
  },
] as any[];
