import AxiosMockAdapter from 'axios-mock-adapter';

import instance from '@/utils/http';

import mockData from './mock.json';

const mock = new AxiosMockAdapter(instance, { delayResponse: 1000 });

mock.onGet(/\/api\/gas-threads$/).reply(200, mockData);
mock.onGet(/gas-threads\/([^/]+)$/).reply(config => {
  const match = config.url.match(/gas-threads\/([^/]+)$/);
  const id = match ? match[1] : null;
  const response = mockData.find(item => item.id === id);
  if (response) {
    return [200, response];
  } else {
    return [404, { message: 'Station not found' }];
  }
});

mock.onAny().passThrough();
