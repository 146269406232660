import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';
import { GasThread, GasThreadFormData } from './types';

export const gasThreadApi = createApi({
  reducerPath: 'gasThreadApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getGasThreads: builder.query<GasThread[], any>({
      query: () => ({
        url: '/gas-threads',
      }),
    }),
    getGasThreadById: builder.query<GasThread, string>({
      query: id => ({
        url: `/gas-threads/${id}`,
      }),
    }),
    createGasThread: builder.mutation<GasThread, GasThreadFormData>({
      query: data => ({
        url: '/gas-threads',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetGasThreadsQuery,
  useLazyGetGasThreadByIdQuery,
  useGetGasThreadByIdQuery,
  useCreateGasThreadMutation,
} = gasThreadApi;
