import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import data from './mock';
import { Contract } from './types';

const contractSlice = createSlice({
  name: 'contract',
  initialState: {
    data,
  },
  reducers: {
    getContractSuccess: (state, { payload }: PayloadAction<Contract[]>) => {
      state.data = payload;
    },
  },
  selectors: {
    contractSelector: state => state.data,
    contractByIdSelector: (state, id: string): Contract | undefined =>
      id ? state.data.find(org => org.id === id) : undefined,
  },
});

export const { getContractSuccess } = contractSlice.actions;

export const { contractSelector, contractByIdSelector } =
  contractSlice.selectors;

export default contractSlice;
