import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';
import { Sector } from './types';

export const sectorApi = createApi({
  reducerPath: 'sectorApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getSector: builder.query<Sector[], void>({
      query: () => ({
        url: '/sectors',
      }),
    }),
  }),
});

export const { useGetSectorQuery } = sectorApi;
