import { ReactNode } from 'react';

import 'dayjs/locale/uk.js';

import CssBaseline from '@mui/joy/CssBaseline';
import {
  THEME_ID as JOY_THEME_ID,
  CssVarsProvider as JoyProvider,
} from '@mui/joy/styles';
import {
  THEME_ID as MUI_THEME_ID,
  Experimental_CssVarsProvider as MaterialProvider,
} from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { joyTheme, materialTheme } from '../utils/theme';

const Provider = ({ children }: { children: ReactNode }) => {
  return (
    <MaterialProvider theme={{ [MUI_THEME_ID]: materialTheme }}>
      <JoyProvider theme={{ [JOY_THEME_ID]: joyTheme }}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'uk'}>
          {children}
        </LocalizationProvider>
      </JoyProvider>
    </MaterialProvider>
  );
};

export default Provider;
