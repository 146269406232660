export default [
  {
    id: 'ТИС000005',
    name: 'АрселорМіттал Кривий Ріг',
    fullName: 'АрселорМіттал Кривий Ріг',
    economicSectorId: '20105631458',
    taxCode: '20105631458',
    classifierId: 'Фізична особа',
    eic: '1666993',
  },
  {
    id: 'ТИС000006',
    name: 'Южкокс',
    fullName: 'Южкокс',
    economicSectorId: '20105631458',
    taxCode: '20105631458',
    classifierId: 'Юр. особа',
    eic: '3331993',
  },
  {
    id: 'ТИС000004',
    name: 'ГРС Козятин',
    fullName: 'ГРС Козятин',
    economicSectorId: '20105631458',
    taxCode: '20105631458',
    classifierId: 'ФОП',
    eic: '11193',
  },
  {
    id: 'ТИС000003',
    name: 'АрселорМіттал Кривий Ріг',
    fullName: 'АрселорМіттал Кривий Ріг',
    economicSectorId: '20105631458',
    taxCode: '20105631458',
    classifierId: 'ФОП',
    eic: '1993333',
  },
  {
    id: 'ТИС000001',
    name: 'АрселорМіттал Кривий Ріг',
    fullName: 'АрселорМіттал Кривий Ріг',
    economicSectorId: '20105631458',
    taxCode: '20105631458',
    classifierId: 'Юр. особа',
    eic: '123',
  },
  {
    id: 'ТИС000002',
    name: 'ГРС Козятин',
    fullName: 'ГРС Козятин',
    economicSectorId: '20105631458',
    taxCode: '20105631458',
    classifierId: 'Фізична особа',
    eic: '1993',
  },
] as any[];
