import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash-es';

import {
  Employee,
  EmployeeFilters,
  EmployeeInput,
} from '@/features/employees/types';
import { EmployeeFormValues } from '@/forms/EmployeeForm';
import normalizePayload from '@/forms/utils/normalizePayload';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

const normalizeEmployeePayloadBeforeMutation = (values: EmployeeFormValues) => {
  let payload: Partial<EmployeeInput> = omit(values, [
    'organization',
    'department',
    'position',
  ]);
  payload.organizationId = values.organization.id;
  payload.departmentId = values.department.id;
  payload.positionId = values.position.id;
  payload = normalizePayload(payload);
  return payload;
};

const normalizeEmployeeResponse = (data: Employee) => {
  if (data?.email === null) {
    data.email = '';
  }
  if (data?.phoneNumber === null) {
    data.phoneNumber = '';
  }
  if (data?.startDate === null) {
    data.startDate = '';
  }
  if (data?.endDate === null) {
    data.endDate = '';
  }
  return data;
};

export const employeesApi = createApi({
  reducerPath: 'employeesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/employees`,
  }),
  tagTypes: ['Employees', 'Employee'],
  endpoints: builder => {
    return {
      getEmployees: builder.query<Employee[], EmployeeFilters | void>({
        query: filters => ({
          url: `/query`,
          method: 'POST',
          data: filters,
        }),
        providesTags: ['Employees'],
      }),
      getEmployeeById: builder.query<Employee, string>({
        query: id => ({
          url: `/${id}`,
        }),
        transformResponse: normalizeEmployeeResponse,
        providesTags: result => [{ type: 'Employee', id: result.id }],
      }),
      createEmployee: builder.mutation<Employee, EmployeeFormValues>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data: normalizeEmployeePayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Employees',
          { type: 'Employee', id: result.id },
        ],
      }),
      updateEmployeeById: builder.mutation<Employee, EmployeeFormValues>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: normalizeEmployeePayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Employees',
          { type: 'Employee', id: result.id },
        ],
      }),
      deleteEmployeeById: builder.mutation<Employee, string>({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => ['Employees', { type: 'Employee', id }],
      }),
    };
  },
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeByIdQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeByIdMutation,
} = employeesApi;
