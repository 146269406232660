import Pages from '@/pages';

import BoltOutlined from '@mui/icons-material/BoltOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import PieChartOutlined from '@mui/icons-material/PieChartOutlined';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';
import SvgIcon from '@mui/joy/SvgIcon';

import OrgsIcon from '@assets/orgs.svg?react';

import { Nav } from './types';

const navigationConfig: Nav = [
  {
    type: 'nav',
    collapse: false,
    items: [
      {
        icon: (
          <SvgIcon sx={{ width: 18, height: 18, color: 'common.white' }}>
            <OrgsIcon />
          </SvgIcon>
        ),
        title: 'Мої організації',
        path: '/',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'nav',
    title: 'Комерційні споживачі',
    collapse: true,
    items: [
      { icon: <PersonOutlined />, title: 'Контрагенти', path: '/counterparts' },
      { icon: <BoltOutlined />, title: 'Договори', path: '/contracts' },
      {
        icon: <BoltOutlined />,
        title: 'Комерційні точки обліку',
        path: '/commercial-accounting-points',
      },
      {
        icon: <BoltOutlined />,
        title: 'Акти по точкам обліку',
        path: '/count',
      },
    ],
  },
  { type: 'divider', show: isMinimized => isMinimized },
  {
    type: 'nav',
    title: 'ГРС',
    collapse: true,
    items: [
      {
        icon: <PieChartOutlined />,
        title: 'ГРС',
        path: '/gas-distribution-stations',
      },
      {
        icon: <DashboardOutlined />,
        title: 'Нитки ГРС',
        path: '/gas-threads',
      },
      {
        type: 'nav',
        collapse: true,
        title: 'Точки обліку ГРС',
        icon: <DashboardOutlined />,
        items: [
          {
            title: 'Точки обліку ГРС',
            path: '/metering-points',
          },
          {
            title: 'Акти по точкам обліку ГРС',
            path: '/count',
          },
        ],
      },
      {
        icon: <DashboardOutlined />,
        title: 'Сектори',
        path: '/counter',
      },
      {
        type: 'nav',
        icon: <DashboardOutlined />,
        collapse: true,
        title: 'Перемички',
        path: Pages.SECTOR_BRIDGES,
        items: [
          {
            title: 'Акти по перемичкам',
            path: Pages.BRIDGE_ACTS,
          },
        ],
      },
      {
        icon: <DashboardOutlined />,
        title: 'Маршрути',
        path: '/counterparts',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'nav',
    collapse: false,
    items: [
      { icon: <TextSnippetOutlined />, title: 'Опитування модемів', path: '/' },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Оперативний облік',
        path: '/counterparts',
      },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Фактичний облік',
        path: '/counterparts',
      },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Аналітика',
        path: '/counterparts',
      },
    ],
  },
];

export default navigationConfig;
