import AxiosMockAdapter from 'axios-mock-adapter';

import instance from '@/utils/http';

import mockData from './mock.json';

const mock = new AxiosMockAdapter(instance, { delayResponse: 1000 });

mock.onPost(/\/api\/subdivisions\/query$/).reply(200, mockData);

const subdivisionByIdRegExp = /subdivisions\/([^/]+)$/;

mock.onGet(subdivisionByIdRegExp).reply(config => {
  const match = config.url.match(subdivisionByIdRegExp);
  const id = match ? Number(match[1]) : null;
  const response = mockData.find(item => item.id === id);
  if (response) {
    return [200, response];
  } else {
    return [404, { message: 'Station not found' }];
  }
});

mock.onAny().passThrough();
