import AxiosMockAdapter from 'axios-mock-adapter';

import instance from '@/utils/http';

import mockData from './mock.json';

const mock = new AxiosMockAdapter(instance, { delayResponse: 1000 });

mock.onGet(/\/api\/sectors\/sector-bridges\/$/).reply(200, mockData);
mock.onGet(/sector-bridges\/([^/]+)$/).reply(config => {
  const match = config.url.match(/sector-bridges\/([^/]+)$/);
  const id = match ? Number(match[1]) : null;
  const response = mockData.find(item => item.id === id);
  if (response) {
    return [200, response];
  } else {
    return [404, { message: "Sector's bridge not found" }];
  }
});

mock.onAny().passThrough();
