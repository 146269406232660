import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash-es';

import {
  Organization,
  OrganizationBase,
  OrganizationFilters,
  OrganizationInput,
  ReferenceBook,
} from '@/features/organizations/types';
import normalizePayload from '@/forms/utils/normalizePayload';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

const normalizeOrganizationPayloadBeforeMutation = (values: Organization) => {
  const payload: Partial<OrganizationInput> = omit(values, [
    'type',
    'classifier',
    'economicSector',
    'representative',
    'address',
  ]);
  payload.typeId = values.type.id;
  payload.classifierId = values.classifier?.id || null;
  payload.economicSectorId = values.economicSector?.id || null;

  payload.representative = {
    ...omit(values.representative, ['department', 'position']),
    departmentId: values.representative.department.id,
    positionId: values.representative.position.id,
  };

  payload.address = {
    settlementId: values.address.settlement.id,
    streetId: values.address.street.id,
    streetType: values.address.street.streetType,
    streetName: values.address.street.name,
    buildingNumber: values.address.buildingNumber,
    officeNumber: values.address.officeNumber,
  };

  if (payload.address.streetId) {
    delete payload.address.streetType;
    delete payload.address.streetName;
  } else {
    delete payload.address.streetId;
  }

  return normalizePayload(payload);
};
const normalizeOrganizationResponse = (data: Organization) => {
  if (data?.certification.code === null) {
    data.certification.code = '';
  }
  if (data?.certification.date === null) {
    data.certification.date = '';
  }
  if (data?.address?.buildingNumber === null) {
    data.address.buildingNumber = '';
  }
  if (data?.address?.officeNumber === null) {
    data.address.officeNumber = '';
  }
  if (data?.representative?.email === null) {
    data.representative.email = '';
  }
  if (data?.representative?.phoneNumber === null) {
    data.representative.phoneNumber = '';
  }
  if (data?.details.logo === null) {
    data.details.logo = '';
  }
  return data;
};

interface ReferenceBooksRes {
  organizationTypes: ReferenceBook[];
  organizationClassifiers: ReferenceBook[];
  economicSectors: ReferenceBook[];
  departments: ReferenceBook[];
  employeePositions: ReferenceBook[];
  streetTypes: ReferenceBook[];
}

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/organizations`,
  }),
  tagTypes: ['Organizations', 'Organization'],
  endpoints: builder => {
    return {
      getReferenceBooks: builder.query<ReferenceBooksRes, void>({
        query: () => ({
          url: '/reference-books',
        }),
      }),
      getOrganizations: builder.query<
        OrganizationBase[],
        OrganizationFilters | void
      >({
        query: filters => ({
          url: `/query`,
          method: 'POST',
          data: !!filters && filters.name ? filters : { name: null },
        }),
        providesTags: ['Organizations'],
      }),
      getOrganizationById: builder.query<Organization, string>({
        query: id => ({
          url: `/${id}`,
        }),
        transformResponse: normalizeOrganizationResponse,
        providesTags: result => [{ type: 'Organization', id: result.id }],
      }),
      createOrganization: builder.mutation<Organization, Organization>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data: normalizeOrganizationPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Organizations',
          { type: 'Organization', id: result.id },
        ],
      }),
      updateOrganizationById: builder.mutation<Organization, Organization>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: normalizeOrganizationPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Organizations',
          { type: 'Organization', id: result.id },
        ],
      }),
      deleteOrganizationById: builder.mutation<Organization, string>({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => [
          'Organizations',
          { type: 'Organization', id },
        ],
      }),
    };
  },
});

export const {
  useGetReferenceBooksQuery,
  useGetOrganizationsQuery,
  useGetOrganizationByIdQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationByIdMutation,
} = organizationsApi;

export const referenceBookSelector =
  organizationsApi.endpoints.getReferenceBooks.select();
