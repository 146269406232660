import { GridRowId } from '@mui/x-data-grid';

const enum Pages {
  ROOT = '/',
  ONBOARDING = '/onboarding',
  CHANGE_PASSWORD = '/profile/change-password',
  USERS = '/users',
  USER_GROUPS = '/user-groups',
  SECTOR_BRIDGES = '/sector-bridges',
  BRIDGE_ACTS = '/bridge-acts',

  // NOT IN THE ROUTER
  ORGANIZATIONS_PART = `organizations`,
  EMPLOYEES_PART = `employees`,
  SUBDIVISIONS_PART = `subdivisions`,
  GAS_DISTRIBUTION_STATIONS = 'gas-distribution-stations',
  ROLES_PART = 'roles',
  GAS_THREADS = 'gas-threads',
  METERING_POINTS = 'metering-points',
  SECTORS = 'sectors',
  COUNTERPARTS = 'counterparts',
  COMMERCIAL_ACCOUNTING_POINTS = 'commercial-accounting-points',

  CONTRACTS = 'contracts',
}

export const getUserURL = (id: string | GridRowId) => `${Pages.USERS}/${id}`;

export const getOrganizationURL = (id: string | GridRowId) =>
  `/${Pages.ORGANIZATIONS_PART}/${id}`;

export const getSubdivisionURL = (
  orgId: string | GridRowId,
  id: string | GridRowId,
) => `${getOrganizationURL(orgId)}/${Pages.SUBDIVISIONS_PART}/${id}`;

interface GetEmployeesURLProps {
  orgId: string | GridRowId;
  subId?: string | GridRowId;
}

export const getEmployeesURL = ({ orgId, subId }: GetEmployeesURLProps) => {
  let part = '';
  if (subId) {
    part = `${Pages.SUBDIVISIONS_PART}/${subId}/`;
  }
  return `${getOrganizationURL(orgId)}/${part}${Pages.EMPLOYEES_PART}`;
};

export const getEmployeeURL = ({
  orgId,
  subId,
  id,
}: GetEmployeesURLProps & {
  id: string | GridRowId;
}) => `${getEmployeesURL({ orgId, subId })}/${id}`;

export const getOrganizationRolesURL = (id: string | GridRowId) =>
  `${getOrganizationURL(id)}/${Pages.ROLES_PART}`;

export const getSubdivisionRolesURL = (
  orgId: string | GridRowId,
  id: string | GridRowId,
) => `${getSubdivisionURL(orgId, id)}/${Pages.ROLES_PART}`;

export const getUserGroupsURL = (id: string | GridRowId) =>
  `${Pages.USER_GROUPS}/${id}`;

export const getSectorBridgeURL = (id: string | GridRowId) =>
  `${Pages.SECTOR_BRIDGES}/${id}`;

export default Pages;
