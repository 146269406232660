import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';
import { GasDistributionStation } from './types';

export const gasDistributionStationsApi = createApi({
  reducerPath: 'stationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getStations: builder.query<GasDistributionStation[], any>({
      query: () => ({
        url: '/gas-distribution-stations',
      }),
    }),
    createStation: builder.mutation<GasDistributionStation, any>({
      query: data => ({
        url: '/gas-distribution-stations',
        method: 'POST',
        data,
      }),
    }),
    getStationById: builder.query<GasDistributionStation, string>({
      query: id => ({
        url: `/gas-distribution-stations/${id}`,
      }),
    }),
    getFilterData: builder.query({
      query: () => ({
        url: '/gas-distribution-stations-filter',
      }),
    }),
  }),
});

export const {
  useGetStationsQuery,
  useGetStationByIdQuery,
  useLazyGetStationByIdQuery,
  useCreateStationMutation,
} = gasDistributionStationsApi;
