import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BridgeAct,
  BridgeActFilters,
  BridgeActInput,
} from '@/features/bridgeActs/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';

const normalizeBridgeActPayloadBeforeMutation = (values: BridgeActInput) => {
  return {
    sectorBridgeId: values.sectorBridge.id,
    stateId: values.stateId,
    dateCreated: `${values.date} ${values.time}`,
  };
};

export const bridgeActsApi = createApi({
  reducerPath: 'bridgeActsApi',
  baseQuery: axiosBaseQuery({
    // TODO sync with BE
    // TODO endpoints not relevant
    baseUrl: `${import.meta.env.VITE_API_URL}/sectors/bridge-acts`,
  }),
  tagTypes: ['BridgeActs', 'BridgeAct'],
  endpoints: builder => ({
    getBridgeActs: builder.query<BridgeAct[], BridgeActFilters | void>({
      // TODO add filters logic
      query: () => ({
        url: `/`,
      }),
      providesTags: ['BridgeActs'],
    }),
    createBridgeAct: builder.mutation<BridgeAct, BridgeActInput>({
      query: data => ({
        url: ``,
        method: 'POST',
        data: normalizeBridgeActPayloadBeforeMutation(data),
      }),
      invalidatesTags: result => [
        'BridgeActs',
        { type: 'BridgeAct', id: result.id },
      ],
    }),
  }),
});

export const { useGetBridgeActsQuery, useCreateBridgeActMutation } =
  bridgeActsApi;
