import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import data from './mock';
import { CommercialAccountingPoints } from './types';

interface CommercialAccountingPointsState {
  data: CommercialAccountingPoints[];
}

const initialState: CommercialAccountingPointsState = {
  data,
};

const commercialAccountingPointsSlice = createSlice({
  name: 'CommercialAccountingPoints',
  initialState,
  reducers: {
    getCommercialAccountingPointsSuccess: (
      state,
      { payload }: PayloadAction<CommercialAccountingPoints[]>,
    ) => {
      state.data = payload;
    },
  },

  selectors: {
    commercialAccountingPointsSelector: state => state.data,
    commercialAccountingPointsByIdSelector: (state, id: string) =>
      id ? state.data.find(org => org.id === id) : undefined,
  },
});

export const { getCommercialAccountingPointsSuccess } =
  commercialAccountingPointsSlice.actions;

export const {
  commercialAccountingPointsSelector,
  commercialAccountingPointsByIdSelector,
} = commercialAccountingPointsSlice.selectors;

export default commercialAccountingPointsSlice;
