import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash-es';

import {
  Subdivision,
  SubdivisionBase,
  SubdivisionFilters,
  SubdivisionInput,
} from '@/features/subdivisions/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import './mockApi';

const normalizeSubdivisionPayloadBeforeMutation = (values: Subdivision) => {
  const payload: Partial<SubdivisionInput> = omit(values, [
    'type',
    'classifier',
    'economicSector',
    'representative',
    'signatory',
    'address',
  ]);
  payload.typeId = values.type.id;
  payload.classifierId = values.classifier?.id || null;
  payload.economicSectorId = values.economicSector?.id || null;

  payload.representative = {
    ...omit(values.representative, ['department', 'position']),
    departmentId: values.representative.department.id,
    positionId: values.representative.position.id,
  };

  payload.signatory = {
    ...omit(values.signatory, ['department', 'position']),
    departmentId: values.signatory.department.id,
    positionId: values.signatory.position.id,
  };

  payload.address = {
    settlementId: values.address.settlement.id,
    streetId: values.address.street.id,
    streetType: values.address.street.streetType,
    streetName: values.address.street.name,
    buildingNumber: values.address.buildingNumber,
    officeNumber: values.address.officeNumber,
  };

  if (payload.address.streetId) {
    delete payload.address.streetType;
    delete payload.address.streetName;
  } else {
    delete payload.address.streetId;
  }

  return payload;
};
const normalizeSubdivisionResponse = (data: Subdivision) => {
  if (data?.address?.buildingNumber === null) {
    data.address.buildingNumber = '';
  }
  if (data?.address?.officeNumber === null) {
    data.address.officeNumber = '';
  }
  if (data?.representative?.email === null) {
    data.representative.email = '';
  }
  if (data?.representative?.phoneNumber === null) {
    data.representative.phoneNumber = '';
  }
  if (data?.signatory?.email === null) {
    data.signatory.email = '';
  }
  if (data?.signatory?.phoneNumber === null) {
    data.signatory.phoneNumber = '';
  }
  return data;
};

export const subdivisionsApi = createApi({
  reducerPath: 'subdivisionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/subdivisions`,
  }),
  tagTypes: ['Subdivisions', 'Subdivision'],
  endpoints: builder => {
    return {
      getSubdivisions: builder.query<
        SubdivisionBase[],
        SubdivisionFilters | void
      >({
        query: filters => ({
          url: `/query`,
          method: 'POST',
          data: filters,
        }),
        providesTags: ['Subdivisions'],
      }),
      getSubdivisionById: builder.query<Subdivision, string>({
        query: id => ({
          url: `/${id}`,
        }),
        transformResponse: normalizeSubdivisionResponse,
        providesTags: (_, __, id) => [{ type: 'Subdivision', id }],
      }),
      createSubdivision: builder.mutation<Subdivision, Subdivision>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data: normalizeSubdivisionPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Subdivisions',
          { type: 'Subdivision', id: result.id },
        ],
      }),
      updateSubdivisionById: builder.mutation<Subdivision, Subdivision>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: normalizeSubdivisionPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Subdivisions',
          { type: 'Subdivision', id: result.id },
        ],
      }),
      deleteSubdivisionById: builder.mutation<Subdivision, string>({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => [
          'Subdivisions',
          { type: 'Subdivision', id },
        ],
      }),
    };
  },
});

export const {
  useGetSubdivisionsQuery,
  useGetSubdivisionByIdQuery,
  useCreateSubdivisionMutation,
  useUpdateSubdivisionByIdMutation,
} = subdivisionsApi;
