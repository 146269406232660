import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const sectorBridgeStatesApi = createApi({
  reducerPath: 'sectorBridgeStatesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/sector-bridge-states`,
  }),
  tagTypes: ['SectorBridgeStates', 'SectorBridgeState'],
  endpoints: builder => ({
    getSectorBridgeStates: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: ``,
      }),
      providesTags: ['SectorBridgeStates'],
    }),
  }),
});

export const { useGetSectorBridgeStatesQuery } = sectorBridgeStatesApi;
