import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authApi } from '@/features/auth/authApi';
import authSlice from '@/features/auth/authSlice';
import { bridgeActsApi } from '@/features/bridgeActs/bridgeActsApi';
import commercialAccountingPoints from '@/features/commercialAccountingPoints/commercialAccountingPoints';
import commercialAccountingPointsSlice from '@/features/commercialAccountingPoints/commercialAccountingPoints';
import contractSlice from '@/features/contracts/contractsSlice';
import counterpartSlice from '@/features/counterparts/counterparts';
import { employeesApi } from '@/features/employees/employeesApi';
import { gasDistributionStationCategoryApi } from '@/features/gasDistributionStationCategory';
import { gasDistributionStationTypeApi } from '@/features/gasDistributionStationType';
import { gasDistributionStationsApi } from '@/features/gasDistributionStations/gasDistributionStationsApi';
import { gasThreadApi } from '@/features/gasThread/gasThreadApi';
import { locationsApi } from '@/features/locations/locationsApi';
import { meteringPointApi } from '@/features/meteringPoint/meteringPointApi';
import { meteringPointTypeApi } from '@/features/meteringPointType/meteringPointApi';
import { networkTypeApi } from '@/features/networkType/networkTypeApi';
import { organizationsApi } from '@/features/organizations/organizationsApi';
import { ownDemandTypeApi } from '@/features/ownDemandType/ownDemandTypeApi';
import { sectorBridgeStatesApi } from '@/features/sectorBridgeStates/sectorBridgeStatesApi';
import { sectorBridgesApi } from '@/features/sectorBridges/sectorBridgesApi';
import { sectorApi } from '@/features/sectors/sectorApi';
import { subdivisionsApi } from '@/features/subdivisions/subdivisionsApi';
import uiSlice from '@/features/ui/uiSlice';
import { usersApi } from '@/features/users/usersApi';
import usersSlice from '@/features/users/usersSlice';

const rootReducer = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [subdivisionsApi.reducerPath]: subdivisionsApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  [counterpartSlice.name]: counterpartSlice.reducer,
  [commercialAccountingPoints.name]: commercialAccountingPointsSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [gasDistributionStationsApi.reducerPath]: gasDistributionStationsApi.reducer,
  [gasDistributionStationTypeApi.reducerPath]:
    gasDistributionStationTypeApi.reducer,
  [gasDistributionStationCategoryApi.reducerPath]:
    gasDistributionStationCategoryApi.reducer,
  [gasThreadApi.reducerPath]: gasThreadApi.reducer,
  [networkTypeApi.reducerPath]: networkTypeApi.reducer,
  [contractSlice.name]: contractSlice.reducer,
  [meteringPointApi.reducerPath]: meteringPointApi.reducer,
  [meteringPointTypeApi.reducerPath]: meteringPointTypeApi.reducer,
  [sectorApi.reducerPath]: sectorApi.reducer,
  [sectorBridgesApi.reducerPath]: sectorBridgesApi.reducer,
  [sectorBridgeStatesApi.reducerPath]: sectorBridgeStatesApi.reducer,
  [bridgeActsApi.reducerPath]: bridgeActsApi.reducer,
  [ownDemandTypeApi.reducerPath]: ownDemandTypeApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
});

const middlewares = [
  authApi.middleware,
  organizationsApi.middleware,
  subdivisionsApi.middleware,
  usersApi.middleware,
  gasDistributionStationsApi.middleware,
  gasDistributionStationTypeApi.middleware,
  gasDistributionStationCategoryApi.middleware,
  gasThreadApi.middleware,
  networkTypeApi.middleware,
  meteringPointApi.middleware,
  meteringPointTypeApi.middleware,
  sectorApi.middleware,
  sectorBridgesApi.middleware,
  bridgeActsApi.middleware,
  sectorBridgeStatesApi.middleware,
  ownDemandTypeApi.middleware,
  locationsApi.middleware,
  employeesApi.middleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(middlewares),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
